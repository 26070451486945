import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { SmartApiService } from "../../../../shared/services/smart-api.service";
import { Observer, Subscription } from "rxjs";
import { EHealthStatus, IHealthStatus } from "../../../../shared/api";
import packageInfo from '../../../../../../package.json';

@Component({
  selector: 'smart-status',
  templateUrl: './smart-status.component.html',
  styleUrls: ['./smart-status.component.scss']
})


export class SmartStatusComponent implements OnInit, OnDestroy {

  serverUp: boolean | undefined;
  smartHealthSub: Subscription;
  smartHealthObs: Observer<IHealthStatus> = {
    next: (health: IHealthStatus) => {
      console.log(health);
      this.serverUp = health.status == EHealthStatus.HEALTHY;
    },
    error: (response) => {
      console.log(response);
      this.serverUp = false
    },
    complete: () => {}
  }

  connectors: {name: string, up: boolean}[] = [
    { name: "PSWS", up: true },
    { name: "OccupationService", up: true },
  ];
  connectorsOK = this.connectors.filter(connector => connector.up);

  webuiVersion: string = isDevMode() ? `${packageInfo.version}-dev` : `${packageInfo.version}`;

  constructor(private api: SmartApiService) {
    this.smartHealthSub = this.api.health$.subscribe(this.smartHealthObs);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.smartHealthSub.unsubscribe();
  }

}
