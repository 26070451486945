export default {
  'generic': {
    'add': 'přidat',
    'author': 'autor',
    'type': 'typ',
    'name': 'název',
    'edit': 'upravit',
    'cancel': 'zrušit',
    'save': 'uložit',
    'delete': 'smazat',
    'back': 'zpět',
    'try_again': 'zkusit znovu',
    'search': 'vyhledat',
    'email': 'e-mail',
    'and': ' a ',
    'nobody': 'nikdo',
    'anybody': 'kdokoli',
    'everybody': 'všichni',
    'nothing': 'nic',
    'loading': 'načítání…',
    'unknown': 'neznámý',
    'person': 'osoba',
    'group': 'skupina',
    'from': 'od',
    'to': 'do',
    'days': {
      'monday': 'pondělí',
      'tuesday': 'úterý',
      'wednesday': 'středa',
      'thursday': 'čtvrtek',
      'friday': 'pátek',
      'saturday': 'sobota',
      'sunday': 'neděle',
    },
    'language': 'jazyk',
    'languages': {
      'english': 'angličtina',
      'czech': 'čeština',
    },
    'uin': 'UČO',
  }, // 'generic'

  'footer': {
    'mu': 'Masarykova univerzita',
    'ics': 'Ústav výpočetní techniky',
    'releasenotes': 'Release notes',
    'documentation': 'Dokumentace',
    'team': 'Smart team',
  },

  'notifications': {
    'error': 'Chyba',
    'httpError': 'Chyba {statusCode}',
    'serviceError': 'Chyba připojené služby',
    'success': 'Úspěch'
  },  // 'notifications'

  /* Core messages */
  'home': 'Domů',
  'smart': {
    'statusTitle': 'Stav systému SMART',
    'connectors': 'Konektory',
    'tasks': {
      'running': 'Běžící úlohy',
    },
  },
  'automation': 'Automatizace',
  'error': {
    'title': 'Chyba',
    '0': {
      'title': 'Nedefinovaná chyba',
      'explanation': 'Zablokováno CORS nebo firewallem?',
    },
    '400': {
      'title': 'Chybný požadavek',
    },
    '401': {
      'title': 'Neautentizován',
      'header': 'Kdopak že to jsi?',
      'explanation': 'Nejspíše vypršela platnost vaší autentizace a my si tak nemůžeme být jisti, s kým to vlastně jednáme. Prosíme, znovu se přihlašte.',
    },
    '403': {
      'title': 'Zakázáno',
      'header': 'Tak pozor! Co tady děláš?!',
      'explanation': 'Opravdu tu nemáš co dělat. Zaznamenali jsme si tvou lumpárnu a pozorně tě budeme sledovat.',
    },
    '404': {
      'title': 'Nenalezeno',
      'header': 'Jejda! Tuto stránku se nepodařilo najít.',
      'explanation': 'Tak teď opravdu nevíme, co Vám máme zobrazit. Požadovaná stránka neexistuje, nebo byla odstraněna.',
    },
    '405': {
      'title': 'Nepovoleno',
    },
    '500': {
      'title': 'Vnitřní chyba serveru',
      'header': 'Jejda! Něco se ošklivě pokazilo.',
      'explanation': 'Ani my si nejsme jisti, co nám to vylétlo do vzduchu. Mějte s námi trpělivost, přejdeme na to a zase to nahodíme.',
    },
    '504': {
      'title': 'Vypršel časový limit brány',
    },
  }, // 'error'
  'login': {
    'title': 'Přihlášení',
    'welcome': 'Vítejte v aplikaci {appName}',
    'description': 'Automatizace správy IT služeb',
    'cardTitle': 'Vyberte metodu přihlášení',
  }, // 'login'
  'profile': {
    'title': 'Uživatelský profil',
    'phone': 'Telefon',
    'position': 'Pracovní zařazení',
    'role': 'Role',
    'show': 'Zobrazit profil',
    'logout': 'Odhlásit se',
  }, // 'profile'
  'user': {
    'tasks': {
      'title': 'Moje úlohy',
      'tooltip-no-active': 'Žádné aktivní úlohy',
      'detail': 'Detail úlohy',
      'active': 'Aktivní úlohy',
      'completed': 'Dokončené úlohy',
      'failed': 'Neúspěšné úlohy',
      'all': 'Všechny úlohy',
      'cancel': 'Zrušit úlohu',
      'retry': 'Zkusit znovu',
      'created': 'Vytvořeno',
      'status': {
        'title': 'Stav',
        'CREATED': 'Vytvořeno',
        'SMART_UNAVAILABLE': 'Nedostupný SMART server',
        'REQUEST_ERROR': 'Požadavek selhal',
        'REQUESTED': 'Ověřování',
        'PROCESSING': 'Přidávání do fronty',
        'QUEUED': 'Ve frontě',
        'STARTING': 'Spouštění',
        'RUNNING': 'Zpracovávání',
        'COMPLETED': 'Dokončeno',
        'FAILED': 'Selhalo',
        'CANCEL_REQUESTED': 'Požadováno zrušení',
        'TERMINATING': 'Rušení',
        'CANCELLED': 'Zrušeno',
        'NOTFOUND': 'Nenalezeno',
        'UNKNOWN': 'Neznámý',
      },
      'lastUpdate': 'Poslední změna',
      'priority': {
        'title': 'Priorita',
        'LOW': 'Nízká',
        'NORMAL': 'Běžná',
        'HIGH': 'Vysoká',
        'CRITICAL': 'Kritická',
        'REALTIME': 'V reálném čase',
      },
      'endpoint': 'Koncový bod',
      'credential': 'Účet či token',
      'command': {
        'title': 'Příkaz',
        'parameters': 'Parametry',
      },
      'owner': 'Vlastník',
      'group': 'Skupina',
      'source': {
        'local': 'lokální',
        'online': 'online',
      },
      'localTasksTitle': 'Lokální úlohy',
      'onlineTasksTitle': 'Úlohy na serveru',
    },
    'menu': {
      'help': 'Nápověda',
    },
    'profile': {
      'active': 'Aktivní účet',
      'notes': 'Poznámky',
      'studies': 'Studia',
      'employment': 'Zaměstnání',
    },
    'notifications': {
      'invalidUCO': 'Požadované učo {uco} není platné.',
    }
  }, // 'user'
  'bookmarks': {
    'tip': 'Záložky',
    'add': 'Přidat stránku do záložek',
    'remove': 'Odstranit stránku ze záložek',
    'no-items': 'Zatím žádné záložky',
  }, // 'favorite'
  'settings': {
    'title': 'Nastavení',
    'language': 'Jazyk',
    'appearance': {
      'title': 'Vzhled',
      'theme': 'Téma',
      'light': 'Světlé',
      'dark': 'Tmavé',
      'system': 'Systémové',
      'accentColor': 'Barva zvýraznění',
    }
  }, // 'settings'
  'audit': {
    'title': "Dohled",
    'report': "Hlášení",
    'operations': "Přehled operací",
  }, // 'audit'
  'uiOptions': {
    'title': 'Nastavení Vzhledu Rozhraní',
    'tooltip': 'Nastavení vzhledu rozhraní',
    'dark.tooltip': 'Přepnout světlý/tmavý režim',
    'menuLayout': 'Poloha Menu',
    'menuLayout.left': 'Menu vlevo',
    'menuLayout.top': 'Menu nahoře',
    'animation.title': 'Animace změny stránky',
    'animation.none': 'Žádná',
    'animation.slideUp': 'Posun nahoru',
    'animation.slideDown': 'Posun dolů',
    'animation.fadeIn': 'Pozvolná',
    'animation.zoom': 'Zoom',
    'leftMenuWidth': 'Šířka levého menu',
    'otherOptions': 'Ostatní Volby',
    'colorOptions': 'Nastavení Barev',
    'colorOptions.accent': 'Barva zvýraznění',
    'colorOptions.accent.reset': 'Výchozí',
    'leftMenuShadow': 'Stín levého menu',
    'contentFull': 'Obsah na celou šířku',
    'narrow': 'Úzké zobrazení',
    'contrast': 'Šedé pozadí (pouze světlé téma)',
    'squares': 'Ostré rohy elementů',
    'shadows': 'Stíny elementů',
  }, // 'uiOptions'

  /* Main modules */
  'assets': {
    'title': 'Aktiva',
    'manage': 'Správa aktiv',
    'credentials': 'Účty a tokeny',
    'key': 'Klíč',
    'value': 'Hodnota',
    'addCredential': 'Přidat účet nebo token',
    'endpoints': 'Koncové body',
    'url': 'URL',
    'addEndpoint': 'Přidat koncový bod',
  },
  'processes': {
    'title': 'Procesy',
    'manage': 'Správa procesů',
    'overview': 'Přehled procesů',
    'add': 'Přidat nový proces',
    'addWizard': 'Průvodce přidáním nového procesu',
  }, // 'processes'
  'services': {
    'title': 'Služby',
    'overview': 'Přehled služeb',
    'add': 'Přidat službu',
  }, // 'services'
  'tasks': {
    'title': 'Úlohy',
    'manage': 'Správa úloh',
    'overview': 'Přehled úloh',
    'add': {
      'title': 'Přidat úlohu',
      'wizardTitle': 'Průvodce přidáním nové úlohy',
      'basics': 'Základní údaje',
      'type': 'Typ úlohy',
      'inputs': 'Vstupy úlohy',
      'outputs': 'Výstupy úlohy',
      'extra': 'Další volby',
      'acl': 'Oprávnění',
    },
  }, // 'user-tasks'

  /* Extra modules */
  'events': {
    'title': 'Události'
  },
  'adminTools': {
    'title': 'Nástroje správy',
    'persons': {
      'title': 'Osoby',
      'banning': {
        'title': 'Zákazy služeb',
        'person': 'Osoba',
        'service': 'Služba',
        'start': 'Začátek',
        'expiration': 'Konec',
        'creator': 'Zadavatel',
        'createBan': {
          'title': 'Nový zákaz',
          'target': 'UČO',
          'service': 'Služba',
          'reason': 'Provinění',
          'expiration': 'Platnost do (včetně)',
          'successNotificationMsg': 'Uživateli {uco} byla zakázána služba {service}.',
          'errorNotificationMsg': 'Vytvoření zákazu selhalo neočekávanou chybou.'
        },
        'filterHint': 'Filtrovat zákazy podle UČO',
        'activeFilter': 'Aktivní filtr',
        'state': {
          'title': 'Status',
          'Running': 'Aktivní',
          'Terminated': 'Ukončen',
          'Expired': 'Vypršel',
        },
        'banReason': 'Důvod zákazu',
        'terminationReason': 'Důvod zrušení',
        'terminationReasonHint': 'Vyplňte důvod předčasného ukončení zákazu',
        'terminationSubmit': 'Ukončit zákaz',
        'terminationSuccessNotification': 'Zákaz služby {service} pro uživatele {uco} byl ukončen.',
      },
    },
    'm365': {
      'title': 'Microsoft 365',
      'securityGroups': {
        'title': 'Moderace skupin',
        'searchHint': 'Část názvu nebo e-mailu skupiny',
        'groupIdentifier': 'Identifikátor skupiny',
        'groupName': 'Název skupiny',
        'groupEmail': 'Primární e-mail',
        'owner': 'Správce',
        'moderated': 'Moderována',
        'moderatedTooltip': 'V moderovaných skupinách musí každou zprávu schválit moderátor (s výjimkou zpráv od nemoderovaných uživatelů nebo skupin).',
        'moderators': 'Moderátoři',
        'moderatorMissingError': 'Pro moderované skupiny je vyžadován alespoň jeden moderátor.',
        'senders': 'Povolení odesílatelé',
        'sendersTooltip': 'Uživatelé, kteří mají povoleno do skupiny posílat zprávy. Pokud není nastaveno, může přispívat kdokoli.',
        'unmoderated': 'Nemoderovaní',
        'users': '{userCnt, plural, =0{} one{1 uživatel} few{# uživatelé} other{# uživatelů}}',
        'groups': '{groupCnt, plural, =0{} one{1 skupina} few{# skupiny} other{# skupin}}',
        'addPerson': 'Přidat uživatele',
        'addGroup': 'Přidat skupinu',
        'addModerator': 'Přidat moderátora',
        'addSender': 'Přidat odesílatele',
        'addUnmoderated': 'Přidat nemoderovaného odesílatele',
      },
      'sharepoint': {
        'title': 'SharePoint',
        'searchHint': 'Zadejte celé URL SharePointu nebo část za teams/sites',
        'url': 'URL SharePointu',
        'storageTitle': 'Název',
        'searchTextEmptyError': 'Vyhledávaný text nemůže být prázdný',
        'searchIn': 'Vyhledat',
        'standaloneSites': 'samostatné SharePointy',
        'connectedSites': 'SharePointy týmů, skupin M365, komunit Engage',
        'usage': 'Využito',
        'usageTooltip': 'Využito {usage} MB',
        'warning': 'Upozornění',
        'disabled': 'vypnuto',
        'quota': 'Kvóta',
        'sharing': 'Sdílení',
        'sharingCapabilities': {
          'Disabled': 'Nesdílet mimo organizaci',
          'ExistingExternalUserSharingOnly': 'Sdílet s existujícími externími uživateli',
          'ExternalUserSharingOnly': 'Sdílet s přihlášenými externími uživateli',
          'ExternalUserAndGuestSharing': 'Sdílet s kýmkoliv pomocí odkazu (anonymně)',
        },
        'durationWarning': 'Vyhledávání úložišť je časově náročná operace, která může trvat i několik minut.',
      }
    },  // m365
    'study-rooms': {
      'title': 'Studovny',
      'schedule': {
        'title': 'Rozvrhy',
        'studyroom': 'Studovna',
        'schedules': 'Rozvrhy',
        'schedule': 'Rozvrh',
        'closures': 'Uzávěrky',
        'closure': 'Uzávěrka',
        'curentlyClosedMsg': 'Tato studovna je momentálně uzavřena.',
        'openStudyroom': 'Ukončit uzávěrku',
        'forever': 'navždy',
        'addSchedule': 'Přidat rozvrh',
        'newSchedule': 'Nový rozvrh',
        'addClosure': 'Přidat uzávěrku',
        'deleteInterval': 'Smazat tento úsek',
        'emptyScheduleError': 'Rozvrh nesmí být prázdný. Chcete-li studovnu uzavřít, použijte uzávěru.',
        'useCurrentForEmpty': 'Pro nevyplněné dny použít současný rozvrh.',
        'time-limited': 'Časově {gender, select, male {omezený} female {omezená} other {omezené}}',
        'unlimited': '{gender, select, male {Neomezený} female {Neomezená} other {Neomezené}}',
        'default': 'Výchozí',
        'valid-from': '{gender, select, male {Platný} female {Platná} other {Platné}} od',
        'closurePastError': 'Uzávěrka musí začínat v budoucnosti.',
        'midnightInfo': 'Platnost bude nastavena do půlnoci (24:00)',
        'validityEmpty': 'Rozvrh musí mít definovanou dobu platnosti.',
        'notifications': {
          'unknownValidityMode': 'Mód účinnosti není rozpoznán, rozvrh nelze vytvořit.',
          'newClosureSuccess': 'Nová uzávěra byla úspěšně vytvořena.',
          'closureDeleteSuccess': 'Uzávěrka byla smazána.',
          'closureDeleteError': 'Uzávěrka s daným ID nebyla nalezena, a tak nemohla být smazána.',
          'openStudyroomSuccess': 'Uzávěrka studovny byla ukončena.',
          'openStudyroomError': 'Nebyla evidována žádná probíhající uzávěrka této studovny.',
          'newScheduleSuccess': 'Nový rozvrh byl úspěšně přidán.',
          'pastScheduleError': 'Nelze smazat aktuálně probíhající či ukončený rozvrh.',
          'missingId': 'Chybí identifikační číslo rozvrhu.',
          'scheduleDeleteSuccess': 'Rozvrh byl smazán.',
          'scheduleDeleteError': 'Rozvrh nemohl být smazán.',
        },
        'tooltips': {
          'deleteDefaultSchedule': 'Platnost předchozího rozvrhu bude prodloužena do nekonečna',
        }
      },
      'reservation': {
        'title': 'Rezervace',
        'locality': 'Lokalita',
        'studyroom': 'Studovna',
        'addReservation': 'Nová rezervace',
        'localityReservations': 'Rezervace celé lokality',
        'studyroomReservations': 'Rezervace jednotlivých studoven',
        'noLocalitySelected': 'Nevybrána žádná lokalita',
        'from': 'Od',
        'to': 'Do',
        'note': 'Účel',
        'createReservationTitle': 'Vytvořit novou rezervaci',
        'multi-day': 'Vícedenní',
        'date': 'Datum',
        'time': 'Čas',
        'create': 'Rezervovat',
        'noteValidationError': 'Popis účelu musí mít alespoň 3 znaky.',
        'timeTooltip': 'Pokud není vybrán žádný čas, bude rezervován celý den.',
        'timeValidationError': 'Musí začínat v budoucnosti a trvat alespoň 10 minut.',
        'endReservation': 'Ukončit',
      },
      'occupation': {
        'shortTitle': 'Obsazenost',
        'title': 'Obsazenost studoven',
        'filterLocalities': 'Filtrovat lokality',
        'availableComputers': 'Dostupných počítačů',
        'computerStatus': {
          'PowerOff': 'Počítač je vypnutý',
          'PowerOn': 'Počítač je zapnutý',
          'LoggedIn': 'Přihlášen uživatel {uco}',
          'UserLeft': 'Přihlášený uživatel {uco} opustil studovnu',
          'MultiLog': 'Uživatel {uco} je přihlášen na více počítačích',
          'External': 'Přihlášený uživatel mimo správu UVT',
          'Maintenance': 'Probíhá údržba',
          'unknown': 'Počítač hlásí neznámý stav: {status}'
        }
      },
    },  // Study rooms
    'libraries': {
      'title': 'Knihovny',
      'sponsoring': {
        'title': 'Sponzorování účtů',
        'searchHint': 'Hledat účet podle jména, UČO, nebo e-mailu',
        'tabSearch': 'Hledat účty',
        'tabSponsoredByMe': 'Mnou sponzorované účty',
        'initPlaceholder': 'Začněte vyhledáním účtů',
        'sponsors': 'Sponzoři',
        'activeSponsors': 'Aktivní sponzoři',
        'libraryServices': {
          'title': 'Členství ve skupině',
          'tooltip': 'Expirace členství ve skupině MU Library Guests',
          'unlimited': 'Neomezené',
          'notMember': 'Není členem',
        },
        'account': {
          'name': 'Jméno',
          'status': {
            'title': 'Status',
            'active': 'Aktivní',
            'expired': 'Expirovaný',
          },
          'resetPassword': 'Resetovat heslo',
        },
        'createPublic': {
          'title': 'Vytvořit účet pro veřejnost',
          'buttonTxt': 'Vytvořit účet',
          'titleBefore': 'Titul (před jm.)',
          'firstName': 'Křestní jméno',
          'surname': 'Příjmení',
          'nameError': 'Jméno musí být zadáno.',
          'titleAfter': 'Titul (za jm.)',
          'emailError': 'Zadejte platný e-mail.',
          'languageError': 'Neznámý jazyk.',
          'expiration': 'Platnost',
          'expirationError': 'Doba expirace veřejného účtu musí být nastavena.'
        },
        'sponsorAccount': {
          'buttonTxt': 'Sponzorovat',
          'editButtonTxt': 'Upravit',
          'editButtonTxtLong': 'Upravit mé sponzorování',
          'mySponsorship': 'Mé sponzorování',
          'sponsorUntil': 'Sponzorovat do'
        },
        'notifications': {
          'passwordResetSuccess': 'Zpráva pro obnovení hesla byla úspěšně odeslána.',
          'sponsorSuccess': 'Uživatelský účet byl úspěšně sponzorován.',
          'expirationInPast': 'Expirace sponzorování nemůže být v minulosti',
        }
      }
    }, // Libraries
  }, // 'adminTools'
}
