import { menuContents } from "../menu.interface";

export const MainMenu: menuContents = [
  {
    title: 'home',
    icon: 'fe fe-home',
    target: '/',
  },
  {
    title: 'automation',
    items: [
/**
      {
        title: 'processes.title',
        icon: 'fe fe-git-pull-request',
        items: [
          {
            title: 'processes.overview',
            target: '/processes/overview'
          },
          {
            title: 'processes.add',
            target: '/processes/add'
          },
        ],
      },
*/
      {
        title: 'tasks.title',
        icon: 'fe fe-play',
        items: [
          {
            title: 'tasks.overview',
            target: '/tasks/overview'
          },
          {
            title: 'tasks.add.title',
            target: '/tasks/add'
          },
        ],
      },
/**
      {
        title: 'services.title',
        icon: 'fe fe-link',
        items: [
          {
            title: 'services.overview',
            target: '/services/overview'
          },
          {
            title: 'services.add',
            target: '/services/add'
          },
        ],
      },
*/
      {
        title: 'assets.title',
        icon: 'fe fe-package',
        restrictTo: 'res:SMART_users',
        items: [
          {
            title: 'assets.credentials',
            target: '/assets/credentials'
          },
          {
            title: 'assets.endpoints',
            target: '/assets/endpoints'
          }
        ],
      },
    ],
  },
  {
    title: 'adminTools.title',
    items: [
      {
        title: 'adminTools.m365.title',
        icon: 'fa-regular fa-address-book',
        restrictTo: 'res:smart:m365',
        items: [
          {
            title: 'adminTools.m365.securityGroups.title',
            target: '/admin-tools/m365/security-groups',
            restrictTo: 'res:smart:m365:security_groups:view',
          },
          {
            title: 'adminTools.m365.sharepoint.title',
            target: '/admin-tools/m365/sharepoint',
            restrictTo: 'res:smart:m365:storages:view',
          }
        ],
      },
      {
        title: 'adminTools.persons.title',
        // icon: 'fe fe-shield',
        icon: 'fa-regular fa-user',
        restrictTo: 'res:smart:persons',
        items: [
          {
            title: 'adminTools.persons.banning.title',
            target: '/admin-tools/persons/banning',
            restrictTo: 'res:smart:persons:bans:view',
          },
        ]
      },
      {
        title: 'adminTools.study-rooms.title',
        icon: 'fa-solid fa-landmark',
        restrictTo: 'res:smart:studyrooms',
        items: [
          {
            title: 'adminTools.study-rooms.schedule.title',
            target: '/admin-tools/study-rooms/schedule',
            restrictTo: 'res:smart:studyrooms:schedule:view',
          },
          {
            title: 'adminTools.study-rooms.reservation.title',
            target: '/admin-tools/study-rooms/reservation',
            restrictTo: 'res:smart:studyrooms:reservation:view',
          },
          {
            title: 'adminTools.study-rooms.occupation.shortTitle',
            target: '/admin-tools/study-rooms/occupation',
            restrictTo: 'res:smart:studyrooms:occupation:view',
          }
        ],
      },
      {
        title: 'adminTools.libraries.title',
        icon: 'fe fe-book-open',
        restrictTo: 'res:smart:libraries:sponsoring',
        items: [
          {
            title: 'adminTools.libraries.sponsoring.title',
            target: '/admin-tools/libraries/sponsoring',
            restrictTo: 'res:smart:libraries:sponsoring'
          }
        ]
      }
    ],
  },
/**
  {
    title: 'audit.title',
    items: [
      {
        title: 'audit.report',
        icon: 'fe fe-activity',
        items: [
          {
            title: 'audit.operations',
            target: '/reports/operations'
          }
        ],
      },
    ],
  },
*/
]
