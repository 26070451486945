<nav id="main-menu">
    <ul nz-menu nzMode="inline" [nzInlineCollapsed]="collapsed" class="{{collapsed ? 'menu-collapsed' : ''}}">

      <!-- Unpacked ngFor so we can display different templates based on item type -->
      <ng-template ngFor let-item [ngForOf]="contents">

        <!-- Menu Section -->
        <ng-container *ngIf="isMenuSection(item); else menuLink">
          <ng-container *ngTemplateOutlet="menuSection; context: { $implicit: item }"></ng-container>
        </ng-container>

        <!-- Menu Link -->
        <ng-template #menuLink>
          <li nz-menu-item nzMatchRouter="true" nzMatchRouterExact="true" routerLink="{{item.target}}" class="menu-icon-item">
            <span class="icon {{item.icon}}"></span>
            <span class="text">{{item.title | translate}}</span>
          </li>
        </ng-template>

      </ng-template>

      <ng-template #menuSection let-section>
        <li nz-menu-group nzTitle="{{section.title | translate}}"><ul>

          <!-- Unpacked ngFor so we can display different templates based on item type -->
          <ng-template ngFor let-item [ngForOf]="section.items">

            <!-- Category -->
            <ng-container *ngIf="isMenuCategory(item); else sectionMenuLink">
              <ng-container *ngTemplateOutlet="menuCategory; context: { $implicit: item }"></ng-container>
            </ng-container>

            <!-- Menu Link -->
            <ng-template #sectionMenuLink>
              <li nz-menu-item nzMatchRouter="true" routerLink="{{item.target}}" class="menu-icon-item">
                <span class="icon {{item.icon}}"></span>
                <span class="text">{{item.title | translate}}</span>
              </li>
            </ng-template>

          </ng-template>
        </ul></li>
      </ng-template>

      <ng-template #menuCategory let-category>
        <li nz-submenu *ngIf="!category.hasOwnProperty('restrictTo') || auth.authorizeResource(category.restrictTo)"
            [nzTitle]="menuCategoryTitle">
          <ng-template #menuCategoryTitle>
            <span title class="menu-icon-item">
              <span class="icon {{category.icon}}"></span>
              <span class="text">{{category.title | translate}}</span>
            </span>
          </ng-template>
          <ul>

            <ng-template ngFor let-link [ngForOf]="category.items">
              <li nz-menu-item nzMatchRouter="true" routerLink="{{link.target}}" *ngIf="!link.hasOwnProperty('restrictTo') || auth.authorizeResource(link.restrictTo)">
                <span class="text">{{link.title | translate}}</span>
              </li>
            </ng-template>

          </ul>
        </li>
      </ng-template>

    </ul>
</nav>
