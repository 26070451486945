export type menuContents = Array<menuSection | menuIconLink>;

export interface menuSection {
  title: string, // Name identifier of the menu item, will be translated
  items: Array<menuCategory | menuLink | menuIconLink>,
  restrictTo?: string, // Resource granting access to this item and its descendants
}

export function isMenuSection(item: menuSection | menuCategory | menuLink): item is menuSection {
  return (item.hasOwnProperty('items') && !item.hasOwnProperty('icon'));
}

export interface menuCategory {
  title: string, // Name identifier of the menu item, will be translated
  icon: string, // Font Awesome icon specifier
  restrictTo?: string, // Resource granting access to this item and its descendants
  items: Array<menuLink>,
}

export function isMenuCategory(item: menuSection | menuCategory | menuLink): item is menuCategory {
  return (item.hasOwnProperty('items') && item.hasOwnProperty('icon'));
}

export interface menuLink {
  title: string, // Name identifier of the menu item, will be translated
  target: string, // Router link URL of the target
  restrictTo?: string, // Resource granting access to this item and its descendants
}

export function isMenuLink(item: menuSection | menuCategory | menuLink): item is menuLink {
  return item.hasOwnProperty('target');
}

export interface menuIconLink {
  title: string, // Name identifier of the menu item, will be translated
  icon: string, // Font Awesome icon specifier
  target: string, // Router link URL of the target
  restrictTo?: string, // Resource granting access to this item and its descendants
}
